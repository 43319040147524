import React, { useEffect, useState, useRef } from 'react';
import Wizards3Client from '../../game/wizards3/Wizards3Client';

const Wizards3ClientComponent = ({ coinbox, noCoins }) => {
  const [gameState, setGameState] = useState("");
  const [cardState, setCardState] = useState([]);
  const isMounted = useRef(false);

  useEffect(() => {
    async function setupGame() {
      if (!coinbox) return;
      if (isMounted.current) return;
      isMounted.current = true;
      console.log('Wizards3:', coinbox);
      const newGameClient = new Wizards3Client(coinbox, setGameState, setCardState, '2v2', noCoins);
      console.log('Game client:', newGameClient);
      await newGameClient.start();
      await newGameClient.pause();
      //setGameClient(newGameClient);
    }

    setupGame();
  }, [coinbox, noCoins]);


  return (
    <div className="p-4 w-full">
      {(gameState === 'setupGame') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Detecing Arena<br />Remove all creatures, spells and life</h2>
          <p>
            Red creature positions: {(cardState.redCreatures < 5) ? (5 - cardState.redCreatures) + ' not detected!' : 'Ok!'}<br />
            Red spell position: {(cardState.redSpell !== 1) ? 'Not detected!' : 'Ok!'}<br />
            Red life position: {(cardState.redLife !== 1) ? 'Not detected!' : 'Ok!'}<br />
            Blue creature positions: {(cardState.blueCreatures < 5) ? (5 - cardState.blueCreatures) + ' not detected!' : 'Ok!'}<br />
            Blue spell position: {(cardState.blueSpell !== 1) ? 'Not detected!' : 'Ok!'}<br />
            Blue life position: {(cardState.blueLife !== 1) ? 'Not detected!' : 'Ok!'}
          </p>
        </div>
      )}
      {(gameState === 'boardReady') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Welcome to Tavern Brawl!</h2>
          <p>
            - Draw 5 cards from the stack of Mercenaries and put them into your hand.<br />
            - You can discard any number of them and then draw up to 5 cards again.<br />
            <br />
            To start the Brawl:<br />
            <br />
            - Look into the eyes of that adventurer sitting in front of you!<br />
            - Place your left hand on the left hand symbol.<br />
            - And place your right hand on the right hand symbol.<br />
          </p>
        </div>
      )}
      {(gameState === 'gameStartCountDown') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">{cardState.count}</h2>
        </div>
      )}
      {(gameState === 'gameStart') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Starting!</h2>
        </div>
      )}
      {(gameState === 'realTime') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold" style={{
            background: cardState.overtime ? 'purple' : 'none',
            color: 'white',
          }}>{cardState.overtime ? 'OVERTIME!' : 'Brawling!'}</h2>
          <div className="mb-4 p-4 w-full" style={{
            background: 'red',
            color: 'white',
            transform: 'rotate(180deg)',
          }}>
            Life: {cardState.player1life}<br />
            Creatures: {cardState.player1creatures}<br />
            Mana speed: {cardState.player1manaboost === 0 ? 'Normal' : ('+' + cardState.player1manaboost + ' %')}<br />
            Creature speed: {cardState.player1creatureboost === 0 ? 'Normal' : ('+ ' + cardState.player1creatureboost + ' %')}<br />
            <div className='mana'>
              {cardState.player1mana && cardState.player1mana.length > 0 && (
                cardState.player1mana.map((mana, index) => (
                  <div key={index} style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: mana,
                    display: 'inline-block',
                    margin: '3px',
                    border: '1px solid black',
                  }} />
                ))
              )}
            </div>
          </div>
          <div className="mb-4 p-4 w-full" style={{
            background: 'blue',
            color: 'white',
            transform: '',
          }}>
            Life: {cardState.player2life}<br />
            Creatures: {cardState.player2creatures}<br />
            Mana speed: {cardState.player2manaboost === 0 ? 'Normal' : ('+' + cardState.player2manaboost + ' %')}<br />
            Creature speed: {cardState.player2creatureboost === 0 ? 'Normal' : ('+' + cardState.player2creatureboost + ' %')}<br />
            <div className='mana'>
              {cardState.player2mana && cardState.player2mana.length > 0 && (
                cardState.player2mana.map((mana, index) => (
                  <div key={index} style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: mana,
                    display: 'inline-block',
                    margin: '3px',
                    border: '1px solid black',
                  }} />
                ))
              )}
            </div>
          </div>
        </div>
      )}
      {(gameState === 'cardPlayed') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">A Card has been played!</h2>
        </div>
      )}
      {(gameState === 'overtime') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Overtime overtime!</h2>
        </div>
      )}
      {(gameState === 'resolveCard') && (
        <div className="mb-4 p-4 w-full" style={{
          background: cardState.player === 'player1' ? 'red' : 'blue',
          color: 'white',
          transform: cardState.player === 'player1' ? 'rotate(180deg)' : '',
        }}>
          <h2 className="text-xl font-bold">{
            cardState.isAttack ? cardState.name + ' attacks!' :
              (cardState.name + ' ') + (cardState.cardType === 'creature' ? 'has entered the battlefield! ' : 'played!') + ' Pay the Tower!'
          }
          </h2>
          <div>{cardState.boardStateChanged ? "Change detected" : ""}</div>
          <div className='mana'>
            {cardState.manaCost && cardState.manaCost.length > 0 && (
              cardState.manaCost.map((mana, index) => (
                <div key={index} style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: mana,
                  display: 'inline-block',
                  margin: '3px',
                  border: '1px solid black',
                }} />
              ))
            )}
          </div>
          <img src={'/images/wizards2/' + cardState.name.toLowerCase().replace(' ', '_') + '.jpg'} alt={cardState.name} width="100%" />
        </div>
      )}
      {(gameState === 'creaturePlayed') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Creature played</h2>
        </div>
      )}
      {(gameState === 'changeCardsEvent') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Change cards!</h2>
          <p>Step back and have a short break guys!<br />
            Look around, maybe other mercenaries can help you.<br />
            Discard any number of cards from your hand.<br />
            Then draw up to 5 cards again.</p>
        </div>
      )}
      {(gameState === 'spellPlayed') && (
        <div className="mb-4 p-4 w-full" style={{
          background: cardState.player === 'player1' ? 'red' : 'blue',
          color: 'white',
        }}>
          <h2 className="text-xl font-bold">{cardState.name} is cast!<br />Take it from the spell area to continue the game!</h2>
        </div>
      )}
      {(gameState === 'resumeCountDown') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">{cardState.count}</h2>
        </div>
      )}
      {(gameState === 'waitForBoardToChange') && (
        <div className="mb-4 p-4 w-full" style={{
          background: cardState.player === 'player1' ? 'red' : 'blue',
          color: 'white',
        }}>
          <h2 className="text-xl font-bold">Attack with {cardState.name}</h2>
          <div>{cardState.boardStateChanged ? "Change detected" : ""}</div>
          <img src={'/images/wizards2/' + cardState.name.toLowerCase().replace(' ', '_') + '.jpg'} alt={cardState.name} width="100%" />
        </div>
      )}
      {(gameState === 'gameOver') && (
        <div className="mb-4 p-4 w-full" style={{
          background: cardState.won === 'player1' ? 'red' : 'blue',
          color: 'white',
        }}>
          <h2 className="text-xl font-bold">{cardState.won === 'player1' ? 'Red team' : 'Blue team'} Won the Brawl!</h2>
          <div className="mb-4 p-4 w-full">
            Gametime: {cardState.gameDurationMin} min {cardState.gameDurationSec} sec <br />
            Turnbased time: {cardState.turnBasedTimeMin} min {cardState.turnBasedTimeSec} sec
          </div>
          <div className="mb-4 p-4 w-full" style={{ background: 'red' }}>
            Creatures played: {cardState.player1creaturesplayed}<br />
            Mana played: {cardState.player1manaplayed}<br />
          </div>
          <div className="mb-4 p-4 w-full" style={{ background: 'blue' }}>
            Creatures played: {cardState.player2creaturesplayed}<br />
            Mana played: {cardState.player2manaplayed}<br />
          </div>
        </div>
      )}
    </div>
  );
};

export default Wizards3ClientComponent;
