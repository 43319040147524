import ServerlessClientBase from '../ServerlessClientBase';
import EventManager from './EventManager';
import CreatureAttackManager from './CreatureAttackManager';
import Eventlogger from './EventLogger';


class Wizards3Client extends ServerlessClientBase {
    constructor(coinbox, onGameStateChange = null, onCardStateChange = null, gameMode = '1v1', noCoinsMode = false) {
        super(coinbox);

        this.eventLogger = new Eventlogger(this.firebaseManager);

        // Game State
        this.gameIsActive = false;
        this.currentState = 'setupGame';
        this.currentPlayer = 'box';
        this.turnBasedActive = false;
        this.gameMode = gameMode;

        // Game Data
        this.currentCard = null;
        this.cardQueue = [];
        this.creatures = [];
        this.graveyard = [];
        this.playerStates = {
            'player1': { life: 10 },
            'player2': { life: 10 },
        };
        this.player1ManaBoost = 0;
        this.player2ManaBoost = 0;
        this.player1AttackPct = 1.0;
        this.player2AttackPct = 1.0;
        this.player1ManaPlayed = 0;
        this.player2ManaPlayed = 0;
        this.player1CreaturesPlayed = 0;
        this.player2CreaturesPlayed = 0;
        this.LifeDifferenceBoost = 5;
        this.creatureDifferenceBoost = 5;
        this.overtime = false;
        this.gameStartTime = null;
        this.turnbasedTimeStart = null;
        this.turnbasedTime = 0;
        this.notifcationSoudPriority = 9999;
        this.helperSoundPriority = 9999;
        this.readyForPayment = false;
        this.paymentComplete = false;
        this.manaCardIdsPlayed = [];
        this.nextCardColorPayedFor = [];
        this.addExtraSecondsToCountDown = 0; // bad way - but works

        this.backgroundAudio = {
            'player1': 'background',
            'player2': 'opponent',
            'box': 'notification'
        }

        // Timers and Counters
        this.counter = 3;
        this.creatureId = 0;
        this.currentCreatureAttack = null;
        this.resolveAttackTimer = null;
        this.changeCardsInterval = null;

        // Event Handlers

        this.onGameStateChange = onGameStateChange;
        this.onCardStateChange = onCardStateChange;

        this.noCoinsMode = noCoinsMode;

        if (this.noCoinsMode) {
            this.coinbox.executeOutputCoin = async function () {
                this.outputReady = true;
                this.executeOutputCoinEvent();
            }
        }

        // Constants
        this.secondsPerSpeed = 10;
        this.manaSpeedInMs = this.secondsPerSpeed * 800;
        this.cards = {
            'card111': { 'name': 'Hearth', 'cost': ['green', 'blue', 'yellow', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card112': { 'name': 'Hearth', 'cost': ['green', 'blue', 'yellow', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card113': { 'name': 'Hearth', 'cost': ['green', 'blue', 'yellow', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card114': { 'name': 'Hearth', 'cost': ['green', 'blue', 'yellow', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },

            'card115': { 'name': 'Chest', 'cost': ['green', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card116': { 'name': 'Chest', 'cost': ['green', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card117': { 'name': 'Chest', 'cost': ['green', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card118': { 'name': 'Chest', 'cost': ['green', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },

            'card119': { 'name': 'Lantern', 'cost': ['green', 'yellow', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card120': { 'name': 'Lantern', 'cost': ['green', 'yellow', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card121': { 'name': 'Lantern', 'cost': ['green', 'yellow', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card122': { 'name': 'Lantern', 'cost': ['green', 'yellow', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },

            'card123': { 'name': 'Timber', 'cost': ['yellow', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card124': { 'name': 'Timber', 'cost': ['yellow', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card125': { 'name': 'Timber', 'cost': ['yellow', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card126': { 'name': 'Timber', 'cost': ['yellow', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },

            'card127': { 'name': 'Cloak', 'cost': ['green', 'blue', 'yellow', 'red'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card128': { 'name': 'Cloak', 'cost': ['green', 'blue', 'yellow', 'red'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card129': { 'name': 'Cloak', 'cost': ['green', 'blue', 'yellow', 'red'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card130': { 'name': 'Cloak', 'cost': ['green', 'blue', 'yellow', 'red'], 'strength': 0, 'speed': 0, 'damage': 0 },

            'card131': { 'name': 'Hearth', 'cost': ['green', 'blue', 'yellow', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card132': { 'name': 'Hearth', 'cost': ['green', 'blue', 'yellow', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card133': { 'name': 'Chest', 'cost': ['green', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card134': { 'name': 'Chest', 'cost': ['green', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card135': { 'name': 'Lantern', 'cost': ['green', 'yellow', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card136': { 'name': 'Lantern', 'cost': ['green', 'yellow', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card137': { 'name': 'Timber', 'cost': ['yellow', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card138': { 'name': 'Timber', 'cost': ['yellow', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card139': { 'name': 'Cloak', 'cost': ['green', 'blue', 'yellow', 'red'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card140': { 'name': 'Cloak', 'cost': ['green', 'blue', 'yellow', 'red'], 'strength': 0, 'speed': 0, 'damage': 0 },

            'card54': { 'name': 'Sprite', 'cost': ['blue', 'green'], 'strength': 4, 'speed': 23, 'damage': 1, 'effect': 'mana_yellow' },
            'card57': { 'name': 'Specter', 'cost': ['green', 'purple'], 'strength': 4, 'speed': 20, 'damage': 1, 'effect': 'mana_red' },
            'card34': { 'name': 'Troll', 'cost': ['green', 'red', 'red'], 'strength': 6, 'speed': 18, 'damage': 3, 'effect': 'charge' },
            'card41': { 'name': 'Gargoyle', 'cost': ['red', 'purple'], 'strength': 5, 'speed': 17, 'damage': 1, 'effect': 'charge' },
            'card33': { 'name': 'Basilisk', 'cost': ['green', 'purple'], 'strength': 7, 'speed': 16, 'damage': 1, 'effect': '' },
            'card23': { 'name': 'Dryad', 'cost': ['green'], 'strength': 3, 'speed': 14, 'damage': 1, 'effect': '' },
            'card53': { 'name': 'Fairy', 'cost': ['blue', 'purple'], 'strength': 3, 'speed': 13, 'damage': 1, 'effect': 'mana_green' },
            'card56': { 'name': 'Ogre', 'cost': ['green', 'green', 'red'], 'strength': 7, 'speed': 13, 'damage': 1, 'effect': 'mana_blue' },
            'card35': { 'name': 'Golem', 'cost': ['purple', 'purple'], 'strength': 6, 'speed': 12, 'damage': 2, 'effect': '' },
            'card44': { 'name': 'Ent', 'cost': ['green', 'green', 'green'], 'strength': 8, 'speed': 12, 'damage': 3, 'effect': '' },
            'card50': { 'name': 'Djinn', 'cost': ['yellow', 'yellow', 'yellow', 'red'], 'strength': 10, 'speed': 12, 'damage': 1, 'effect': 'mana_purple' },
            'card14': { 'name': 'Kobold', 'cost': ['red'], 'strength': 2, 'speed': 11, 'damage': 1, 'effect': '' },
            'card59': { 'name': 'Blaze', 'cost': ['blue', 'blue', 'red', 'red'], 'strength': 9, 'speed': 10, 'damage': 1, 'effect': 'mana_yellow' },
            'card49': { 'name': 'Dragon', 'cost': ['red', 'red', 'red', 'purple', 'purple'], 'strength': 10, 'speed': 10, 'damage': 3, 'effect': '' },
            'card55': { 'name': 'Sylph', 'cost': ['blue', 'yellow'], 'strength': 2, 'speed': 9, 'damage': 1, 'effect': 'mana_purple' },
            'card46': { 'name': 'Giant', 'cost': ['red', 'purple', 'purple'], 'strength': 9, 'speed': 9, 'damage': 1, 'effect': '' },
            'card15': { 'name': 'Wisp', 'cost': ['blue'], 'strength': 1, 'speed': 8, 'damage': 1, 'effect': '' },
            'card18': { 'name': 'Imp', 'cost': ['red', 'purple'], 'strength': 2, 'speed': 7, 'damage': 2, 'effect': '' },
            'card26': { 'name': 'Dwarf', 'cost': ['green', 'yellow'], 'strength': 4, 'speed': 7, 'damage': 1, 'effect': '' },
            'card31': { 'name': 'Witch', 'cost': ['purple', 'purple'], 'strength': 5, 'speed': 7, 'damage': 1, 'effect': 'charge' },
            'card48': { 'name': 'Hydra', 'cost': ['blue', 'blue', 'blue', 'blue', 'red', 'red'], 'strength': 10, 'speed': 7, 'damage': 4, 'effect': '' },
            'card39': { 'name': 'Mammut', 'cost': ['green', 'green', 'green', 'green'], 'strength': 7, 'speed': 6, 'damage': 4, 'effect': 'charge' },
            'card37': { 'name': 'Phoenix', 'cost': ['yellow', 'yellow', 'yellow'], 'strength': 7, 'speed': 6, 'damage': 2, 'effect': '' },
            'card45': { 'name': 'Sea serpent', 'cost': ['blue', 'blue', 'blue', 'blue', 'purple'], 'strength': 9, 'speed': 6, 'damage': 3, 'effect': '' },
            'card12': { 'name': 'Snake', 'cost': ['green', 'green'], 'strength': 1, 'speed': 5, 'damage': 3, 'effect': '' },
            'card51': { 'name': 'Wraith', 'cost': ['red', 'purple'], 'strength': 1, 'speed': 5, 'damage': 1, 'effect': 'mana_blue' },
            'card60': { 'name': 'Assassin', 'cost': ['purple', 'purple'], 'strength': 2, 'speed': 5, 'damage': 2, 'effect': 'charge' },
            'card16': { 'name': 'Hobgoblin', 'cost': ['blue', 'red'], 'strength': 3, 'speed': 5, 'damage': 1, 'effect': '' },
            'card28': { 'name': 'Centaur', 'cost': ['yellow', 'yellow'], 'strength': 4, 'speed': 5, 'damage': 1, 'effect': 'charge' },
            'card32': { 'name': 'Gnome', 'cost': ['yellow', 'yellow'], 'strength': 5, 'speed': 5, 'damage': 1, 'effect': '' },
            'card58': { 'name': 'Phantom', 'cost': ['yellow', 'purple', 'purple', 'purple'], 'strength': 6, 'speed': 5, 'damage': 2, 'effect': 'mana_green' },
            'card52': { 'name': 'Satyr', 'cost': ['green', 'yellow', 'yellow', 'yellow'], 'strength': 8, 'speed': 5, 'damage': 1, 'effect': 'mana_red' },
            'card17': { 'name': 'Goblin', 'cost': ['green', 'red'], 'strength': 2, 'speed': 4, 'damage': 1, 'effect': '' },
            'card19': { 'name': 'Leprechaun', 'cost': ['blue', 'yellow'], 'strength': 2, 'speed': 4, 'damage': 1, 'effect': '' },
            'card24': { 'name': 'Orc', 'cost': ['green', 'red', 'red'], 'strength': 4, 'speed': 4, 'damage': 2, 'effect': '' },
            'card40': { 'name': 'Minotaur', 'cost': ['yellow', 'red', 'red', 'red'], 'strength': 7, 'speed': 4, 'damage': 2, 'effect': '' },
            'card38': { 'name': 'Manticore', 'cost': ['green', 'green', 'red', 'red', 'red', 'red'], 'strength': 8, 'speed': 4, 'damage': 4, 'effect': '' },
            'card42': { 'name': 'Griffin', 'cost': ['green', 'green', 'yellow', 'yellow'], 'strength': 8, 'speed': 4, 'damage': 1, 'effect': '' },
            'card43': { 'name': 'Wyvern', 'cost': ['blue', 'blue', 'blue', 'purple', 'purple'], 'strength': 10, 'speed': 4, 'damage': 1, 'effect': '' },
            'card13': { 'name': 'Rogue', 'cost': ['yellow', 'purple'], 'strength': 1, 'speed': 3, 'damage': 1, 'effect': '' },
            'card25': { 'name': 'Banshee', 'cost': ['purple', 'purple', 'purple'], 'strength': 3, 'speed': 3, 'damage': 3, 'effect': '' },
            'card27': { 'name': 'Harpie', 'cost': ['blue', 'blue', 'purple'], 'strength': 5, 'speed': 3, 'damage': 1, 'effect': '' },
            'card20': { 'name': 'Naga', 'cost': ['blue', 'green', 'green', 'green'], 'strength': 6, 'speed': 3, 'damage': 2, 'effect': '' },
            'card36': { 'name': 'Elementalist', 'cost': ['blue', 'blue', 'blue'], 'strength': 5, 'speed': 2, 'damage': 1, 'effect': '' },
            'card29': { 'name': 'Werewolf', 'cost': ['green', 'green', 'red', 'red'], 'strength': 6, 'speed': 2, 'damage': 1, 'effect': '' },
            'card30': { 'name': 'Vampire', 'cost': ['red', 'red', 'purple', 'purple', 'purple'], 'strength': 6, 'speed': 2, 'damage': 2, 'effect': '' },
            'card47': { 'name': 'Noble', 'cost': ['yellow', 'yellow', 'yellow', 'yellow'], 'strength': 8, 'speed': 2, 'damage': 1, 'effect': '' },
            'card11': { 'name': 'Pixie', 'cost': ['yellow', 'yellow', 'purple'], 'strength': 1, 'speed': 1, 'damage': 1, 'effect': '' },
            'card21': { 'name': 'Mermaid', 'cost': ['blue', 'blue', 'blue'], 'strength': 3, 'speed': 1, 'damage': 1, 'effect': '' },
            'card22': { 'name': 'Elf', 'cost': ['green', 'green', 'green', 'yellow'], 'strength': 4, 'speed': 1, 'damage': 1, 'effect': '' },
        }

        // mana coin data

        if (this.gameMode === '1v1') {
            this.coinGeneration = {
                'player1': {
                    'firstdelay': 1000,
                    'delay': this.manaSpeedInMs,
                    'colors': ['red', 'blue', 'green', 'purple', 'yellow'],
                    'firstNumberCoins': 3,
                    'index': 0,
                    'tray': 'tray1'
                },
                'player2': {
                    'firstdelay': 1000,
                    'delay': this.manaSpeedInMs,
                    'colors': ['red', 'blue', 'green', 'purple', 'yellow'],
                    'firstNumberCoins': 3,
                    'index': 0,
                    'tray': 'tray2'
                }
            };
        } else if (this.gameMode === '2v2') {
            this.manaSpeedInMs = this.secondsPerSpeed * 1000;  // a bit slower
            this.coinGeneration = {
                'player1': {
                    'firstdelay': 1000,
                    'delay': this.manaSpeedInMs,
                    'colors': ['red', 'blue', 'green', 'purple', 'yellow'],
                    'firstNumberCoins': 3,
                    'index': 0,
                    'tray': 'tray1'
                },
                'player2': {
                    'firstdelay': 1000,
                    'delay': this.manaSpeedInMs,
                    'colors': ['red', 'blue', 'green', 'purple', 'yellow'],
                    'firstNumberCoins': 3,
                    'index': 0,
                    'tray': 'tray2'
                },
                'player3': {
                    'firstdelay': 1000,
                    'delay': this.manaSpeedInMs,
                    'colors': ['red', 'blue', 'green', 'purple', 'yellow'],
                    'firstNumberCoins': 3,
                    'index': 0,
                    'tray': 'tray3'
                },
                'player4': {
                    'firstdelay': 1000,
                    'delay': this.manaSpeedInMs,
                    'colors': ['red', 'blue', 'green', 'purple', 'yellow'],
                    'firstNumberCoins': 3,
                    'index': 0,
                    'tray': 'tray4'
                }
            };
        }

        // Initialize Managers
        this.creatureAttackManager = new CreatureAttackManager(
            this.handleCreatureReadyToAttack.bind(this),
            this.secondsPerSpeed
        );
        this.eventSchedule = [
            { time: 60000, eventId: 1 },
            { time: 120000, eventId: 1 },
            { time: 240000, eventId: 1 },
            { time: 360000, eventId: 1 },
            { time: 480000, eventId: 1 },
            { time: 600000, eventId: 1 },
            { time: 720000, eventId: 1 },
            { time: 840000, eventId: 1 },
            { time: 960000, eventId: 1 },
            { time: 1080000, eventId: 1 },
            { time: 1200000, eventId: 1 },
            { time: 1320000, eventId: 1 },
        ];
        this.eventManager = new EventManager(this.handleGameEvent.bind(this), this.eventSchedule);

        // Initialize Game
        this.initGame();
    }

    // Initialize Game
    initGame() {
        this.coinbox.on('vision', this.handleVisionEvent);
        this.coinbox.on('inputcoin', this.handleCoinDetected);
        this.coinbox.on('input', this.handleCoinColorDetected);
        this.coinbox.on('output', this.handleCoinOutput);
        this.coinbox.on('connection', this.handleConnection);
        this.coinGeneratorSetup();
        this.coinbox.setColorMode(1);
        this.transitionToState('setupGame');
        this.coinbox.audioPlayer.setVolume("opponent", 0.8);
        this.coinbox.audioPlayer.setVolume("background", 0.8);
        let audioCache = [
            { 'name': 'number_1', 'filename': '/audio/wizards/number_1.mp3' },
            { 'name': 'number_2', 'filename': '/audio/wizards/number_2.mp3' },
            { 'name': 'number_3', 'filename': '/audio/wizards/number_3.mp3' },
            { 'name': 'number_4', 'filename': '/audio/wizards/number_4.mp3' },
            { 'name': 'player_red', 'filename': '/audio/wizards2/player_red.mp3' },
            { 'name': 'player_blue', 'filename': '/audio/wizards2/player_blue.mp3' },
            { 'name': 'pling1', 'filename': '/audio/wizards/pling1.mp3' },
            { 'name': 'waiting', 'filename': '/audio/wizards2/waiting.mp3' },
            { 'name': 'brawl', 'filename': '/audio/wizards2/brawl.mp3' },
            { 'name': 'card_played', 'filename': '/audio/wizards2/card_played.mp3' },
            { 'name': 'creature_played', 'filename': '/audio/wizards2/creature_played.mp3' },
            { 'name': 'mana_added', 'filename': '/audio/wizards2/mana_added.mp3' },
            { 'name': 'mana_removed', 'filename': '/audio/wizards2/mana_removed.mp3' },
            { 'name': 'spell_active', 'filename': '/audio/wizards2/spell_active.mp3' },
            { 'name': 'removemanacard', 'filename': '/audio/wizards2/removemanacard.mp3' },
            { 'name': 'attack_starts', 'filename': '/audio/wizards2/attack_starts.mp3' },
            { 'name': 'creature_attacks', 'filename': '/audio/wizards2/creature_attacks.mp3' },
            { 'name': 'isnowactivefor', 'filename': '/audio/wizards2/isnowactivefor.mp3' },
            { 'name': 'boosting', 'filename': '/audio/wizards2/boosting.mp3' },
            { 'name': 'resource _yellow', 'filename': '/audio/wizards2/resource _yellow.mp3' },
            { 'name': 'resource _blue', 'filename': '/audio/wizards2/resource _blue.mp3' },
            { 'name': 'resource _green', 'filename': '/audio/wizards2/resource _green.mp3' },
            { 'name': 'resource _red', 'filename': '/audio/wizards2/resource _red.mp3' },
            { 'name': 'resource _purple', 'filename': '/audio/wizards2/resource _purple.mp3' },
            { 'name': 'joinsthefight', 'filename': '/audio/wizards2/joinsthefight.mp3' },
            { 'name': 'toenhanceyour', 'filename': '/audio/wizards2/toenhanceyour.mp3' },
            { 'name': 'paythetower', 'filename': '/audio/wizards2/paythetower.mp3' },
            { 'name': 'pause_250', 'filename': '/audio/wizards/pause_250.mp3' },
            { 'name': 'pause_500', 'filename': '/audio/wizards/pause_500.mp3' },
            { 'name': 'now_pay', 'filename': '/audio/wizards2/now_pay.mp3' },
            { 'name': 'yellow_payed', 'filename': '/audio/wizards2/yellow_payed.mp3' },
            { 'name': 'blue_payed', 'filename': '/audio/wizards2/blue_payed.mp3' },
            { 'name': 'green_payed', 'filename': '/audio/wizards2/green_payed.mp3' },
            { 'name': 'red_payed', 'filename': '/audio/wizards2/red_payed.mp3' },
            { 'name': 'purple_payed', 'filename': '/audio/wizards2/purple_payed.mp3' },
            { 'name': 'recruitment_canceled_for', 'filename': '/audio/wizards2/recruitment_canceled_for.mp3' },
            { 'name': 'recruitment_queued_for', 'filename': '/audio/wizards2/recruitment_queued_for.mp3' },
            { 'name': 'recruitment_failed_for', 'filename': '/audio/wizards2/recruitment_failed_for.mp3' },
            { 'name': 'pay_now_to_recruit', 'filename': '/audio/wizards2/pay_now_to_recruit.mp3' },

        ];

        for (const [key] of Object.entries(this.cards)) {
            let shortName = this.getCardShortName(key.replace('card', ''));
            audioCache.push({ 'name': shortName, filename: '/audio/wizards2/' + shortName + '.mp3' });
        }
        this.coinbox.audioPlayer.cacheAudioFiles(audioCache);
        this.gameId = `game-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
        this.eventLogger.setGameId(this.gameId);
    }

    // State Machine Transition
    transitionToState(newState) {
        console.log(`Transitioning to state: ${newState}`);
        this.currentState = newState;
        if (this.onGameStateChange) this.onGameStateChange(this.currentState);
        this.eventLogger.logEvent('state_change', {
            'state': newState,
        });

        switch (newState) {
            case 'setupGame':
                this.setupGame();
                break;
            case 'boardReady':
                this.prepareBoard();
                break;
            case 'gameStartCountDown':
                this.startGameCountDown();
                break;
            case 'gameStart':
                this.startGame();
                break;
            case 'realTime':
                this.enterRealTime();
                break;
            case 'turnBased':
                this.enterTurnBasedMode();
                break;
            case 'changeCardsEvent':
                this.handleChangeCardsEvent();
                break;
            case 'overtime':
                this.handleOverTime();
                break;
            case 'resolveCard':
                this.resolveCurrentCard();
                break;
            case 'resumeCountDown':
                this.startResumeCountDown();
                break;
            case 'gameOver':
                this.endGame();
                break;
            default:
                console.warn(`Unknown state: ${newState}`);
        }
    }

    // State Handlers
    setupGame() {
        // Initial game setup if needed
    }

    async prepareBoard() {
        this.coinbox.setColorMode(3);
        await this.start();
        this.coinbox.audioPlayer.ignoreAllBeforeNow('notification');
        this.coinbox.audioPlayer.play('readytostart', '/audio/wizards2/readytostart.mp3', 10, 'notification');
    }

    startGameCountDown() {
        this.coinbox.audioPlayer.queueOrPlay('game_start', '/audio/wizards/game_start.mp3', 10, 'background');
        this.counter = 3;
        this.countDownTimer = setInterval(() => {
            if (this.counter === 0) {
                clearInterval(this.countDownTimer);
                this.transitionToState('gameStart');
                this.coinbox.audioPlayer.play(
                    `brawl`,
                    `/audio/wizards/brawl.mp3`,
                    5,
                    'notification'
                );
            } else {
                this.coinbox.audioPlayer.play(
                    `number_${this.counter}`,
                    `/audio/wizards/number_${this.counter}.mp3`,
                    5,
                    'notification'
                );
                this.updateCardState({ 'count': this.counter });
                this.counter--;
            }
        }, 1000);
    }

    async startGame() {
        await this.pauseAndClear();
        await this.start();
        this.coinGeneratorStart();
        await this.pause();
        this.gameIsActive = true;
        this.creatureAttackManager.start();
        this.eventManager.start();
        this.transitionToState('realTime');
        this.gameStartTime = Date.now();
    }

    enterRealTime() {
        if (this.turnbasedTimeStart) {
            this.turnbasedTime += Date.now() - this.turnbasedTimeStart;
            this.turnbasedTimeStart = null;
        }
        this.resumeGame();
    }

    async enterTurnBasedMode() {
        this.turnbasedTimeStart = Date.now();
        await this.pauseGame();
        this.transitionToState('resolveCard');
    }

    handleOverTime() {

        this.overtime = true;

        // Start a 30-second timer for the ChangeCards event
        this.changeCardsStartTime = Date.now();
        this.changeCardsDuration = 8000;

        // Start ticking to handle the changeCardsTimer
        if (!this.changeCardsInterval) {
            this.changeCardsInterval = setInterval(() => {
                this.handleChangeCardsTick();
            }, 50);
        }
    }

    handleChangeCardsEvent() {
        this.eventLogger.logEvent('event_changecards_started');
        // Play event-specific audio based on eventId
        this.coinbox.audioPlayer.queueOrPlay(
            'event_changecards_sound',
            '/audio/wizards2/event_changecards_sound.mp3',
            10,
            'spellcast'
        );
        this.coinbox.audioPlayer.queueOrPlay(
            'event_changecards',
            '/audio/wizards2/event_changecards.mp3',
            10,
            'spellcast'
        );

        // Start a 30-second timer for the ChangeCards event
        this.changeCardsStartTime = Date.now();
        this.changeCardsDuration = 25000;

        // Start ticking to handle the changeCardsTimer
        if (!this.changeCardsInterval) {
            this.changeCardsInterval = setInterval(() => {
                this.handleChangeCardsTick();
            }, 50);
        }
    }

    handleChangeCardsTick() {
        const elapsedTime = Date.now() - this.changeCardsStartTime;
        if (elapsedTime >= this.changeCardsDuration) {
            clearInterval(this.changeCardsInterval);
            this.changeCardsInterval = null;
            this.eventLogger.logEvent('event_changecards_ended');
            this.addExtraSecondsToCountDown = 3;
            this.transitionToState('resumeCountDown');
        }
    }

    startResumeCountDown() {

        if (this.changeCardsInterval) {   // super weird that its here
            clearInterval(this.changeCardsInterval);
            this.changeCardsInterval = null;
        }

        this.counter = 0;
        let countDownTick = 1400;
        if (this.addExtraSecondsToCountDown) {
            this.counter += this.addExtraSecondsToCountDown;
            this.addExtraSecondsToCountDown = 0;
            countDownTick = 1000;
        }

        this.countDownTimer = setInterval(() => {
            if (this.counter === 0) {
                clearInterval(this.countDownTimer);
                this.currentCard = null;
                this.currentCreatureAttack = null;
                let gameover = false;

                Object.values(this.playerStates).forEach(playerState => {
                    if (playerState.life === 0) {
                        gameover = true;
                    }
                });

                if (gameover) {
                    this.transitionToState('gameOver');
                } else if (this.processQueuedCards()) {
                    // Stay in turn-based mode
                } else {
                    this.coinbox.audioPlayer.play(`brawl`, `/audio/wizards/brawl.mp3`, 5, 'notification');
                    this.transitionToState('realTime');
                }
            } else {
                this.updateCardState({ 'count': this.counter });
                this.coinbox.audioPlayer.queueOrPlay(
                    `number_${this.counter}`,
                    `/audio/wizards/number_${this.counter}.mp3`,
                    5,
                    'notification'
                );
                this.counter--;
            }
        }, countDownTick);
    }

    endGame() {
        this.gameIsActive = false;
        this.pauseGame();
        this.creatureAttackManager.stop();
        this.eventManager.stop();

        let won = 'player1';
        let lost = 'player2';
        if (this.playerStates['player1'].life === 0) {
            won = 'player2';
            lost = 'player1';
        }
        const data = {
            'won': won, 'lost': lost,
            'player1manaplayed': this.player1ManaPlayed, 'player2manaplayed': this.player2ManaPlayed,
            'player1creaturesplayed': this.player1CreaturesPlayed, 'player2creaturesplayed': this.player2CreaturesPlayed,
            'gameDurationMin': Math.floor((Date.now() - this.gameStartTime) / 60000),
            'gameDurationSec': Math.floor((Date.now() - this.gameStartTime) / 1000) % 60,
            'turnBasedTimeMin': Math.floor(this.turnbasedTime / 60000),
            'turnBasedTimeSec': Math.floor(this.turnbasedTime / 1000) % 60
        };
        this.coinbox.audioPlayer.queueOrPlay('game_won', '/audio/wizards/game_won.mp3', 2, 'notification');
        this.updateCardState(data);
        this.eventLogger.logEvent('game_end', data);
    }

    // Pause and Resume Game Methods
    async pauseGame() {
        this.turnBasedActive = true;
        await this.coinbox.pauseOutput();
        this.pauseAllEvents();
        this.creatureAttackManager.pause();
        this.eventManager.pause();
        await this.coinbox.setColorMode(1);
        this.eventLogger.logEvent('game_realtime_ended');
    }

    calculateManaBoost() {

        const lifeDifference = this.playerStates['player1'].life - this.playerStates['player2'].life;
        const creatureDifference = this.creatures.filter(creature => creature.player === 'player1').length - this.creatures.filter(creature => creature.player === 'player2').length;
        let player1ManaBoost = ((lifeDifference < 0) ? - lifeDifference * this.LifeDifferenceBoost : 0) + ((creatureDifference < 0) ? - creatureDifference * this.creatureDifferenceBoost : 0) + (this.overtime ? 25 : 0);
        let player2ManaBoost = ((lifeDifference > 0) ? lifeDifference * this.LifeDifferenceBoost : 0) + ((creatureDifference > 0) ? creatureDifference * this.creatureDifferenceBoost : 0) + (this.overtime ? 25 : 0);

        this.coinGeneration['player1'].delay = Math.round((this.manaSpeedInMs * (100 - player1ManaBoost)) / 100);
        this.coinGeneration['player2'].delay = Math.round((this.manaSpeedInMs * (100 - player2ManaBoost)) / 100);

        if (player1ManaBoost !== this.player1ManaBoost) {
            const pctChangePlayer1 = (100 - this.player1ManaBoost) / (100 - player1ManaBoost);
            this.coinbox.adjustOutputQueueTimestamps('player1', pctChangePlayer1);
        }
        if (player2ManaBoost !== this.player2ManaBoost) {
            const pctChangePlayer2 = (100 - this.player2ManaBoost) / (100 - player2ManaBoost);
            this.coinbox.adjustOutputQueueTimestamps('player2', pctChangePlayer2);
        }

        this.player1ManaBoost = player1ManaBoost;
        this.player2ManaBoost = player2ManaBoost;
    }

    calculateAttackBoost() {
        const player1AttackPct = 1.0 + (this.overtime ? 0.25 : 0.0);
        const player2AttackPct = 1.0 + (this.overtime ? 0.25 : 0.0);

        if (player1AttackPct !== this.player1AttackPct) {
            this.creatureAttackManager.setPlayerSpeedPercentage('player1', player1AttackPct);
            this.player1AttackPct = player1AttackPct;
        }

        if (player2AttackPct !== this.player2AttackPct) {
            this.creatureAttackManager.setPlayerSpeedPercentage('player2', player2AttackPct);
            this.player2AttackPct = player2AttackPct;
        }
    }

    async resumeGame() {

        this.turnBasedActive = false;
        this.calculateManaBoost();
        this.calculateAttackBoost();

        await this.start();
        this.resumeAllEvents();
        this.creatureAttackManager.resume();
        this.eventManager.resume();
        this.coinbox.setColorMode(3);
        this.updateRealTimeScores();
        this.eventLogger.logEvent('game_realtime_started');
    }

    updateRealTimeScores() {
        if (this.turnBasedActive) return;
        const player1mana = this.coinbox.getCoinsOutColorNames('tray1');
        const player2mana = this.coinbox.getCoinsOutColorNames('tray2');
        const data = {
            'player1life': this.playerStates['player1'].life,
            'player2life': this.playerStates['player2'].life,
            'player1creatures': this.creatures.filter(creature => creature.player === 'player1').length,
            'player2creatures': this.creatures.filter(creature => creature.player === 'player2').length,
            'player1manaboost': this.player1ManaBoost,
            'player2manaboost': this.player2ManaBoost,
            'player1creatureboost': Math.round((this.player1AttackPct - 1.0) * 100),
            'player2creatureboost': Math.round((this.player2AttackPct - 1.0) * 100),
            'overtime': this.overtime,

        };
        if (this.noCoinsMode) {
            data['player1mana'] = player1mana;
            data['player2mana'] = player2mana;
        }
        this.eventLogger.logEvent('game_screen_update', data);
        this.updateCardState(data);
    }

    // Event Handlers
    handleVisionEvent = (event) => {
        let { eventId, player, cardtype, cardId } = event;
        if (this.gameMode === '1v1') {
            if (player === 3) {
                player = 2;
            } else if (player === 4) {
                player = 1;
            }
        }
        this.eventLogger.logEvent('vision_event', {
            eventId,
            player,
            cardtype,
            cardId,
        });
        if (this.currentState === 'gameOver') return;

        switch (eventId) {
            case 1:
                if (this.currentState === 'setupGame') this.transitionToState('boardReady');
                break;
            case 2:

                this.handleCardPlayed(player, cardtype, cardId);
                break;
            case 3:
                this.handleCardRemoved(player, cardtype, cardId);
                break;
            case 4:
                if (this.currentState === 'boardReady') this.transitionToState('gameStartCountDown');
                break;
            case 5:
                this.handleSetupDetectionsFailed(event);
                // we will handle this later
                break;
            case 6:
                console.log('Hand movement detected');
                break;
            case 7:
                console.log('Hand movement stopped');
                break;
            case 8:
                console.log('Artefact area', cardtype, cardId);
                break;
            case 9:
                this.handlePlayerReady(player);
                break;

            default:
                console.log('Unknown vision event ID');
        }
    };

    handlePlayerReady = (player) => {
        console.log('Player ready: ', player);
        //const playerid = 'player' + player;

        //const channel = this.backgroundAudio[playerid];
        //this.coinbox.audioPlayer.queueOrPlay('adventurerready', '/audio/wizards2/adventurerready.mp3', 10, channel);
    };

    handleSetupDetectionsFailed = (event) => {
        if (event.redSpell === 99) {
            this.coinbox.audioPlayer.ignoreAllBeforeNow('notification');
            this.coinbox.audioPlayer.play('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('cannotdetectthe4corners', '/audio/wizards2/cannotdetectthe4corners.mp3', 10, 'notification');
        } else {
            this.coinbox.audioPlayer.ignoreAllBeforeNow('notification');
            this.coinbox.audioPlayer.play('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'notification');
            this.coinbox.audioPlayer.queueOrPlay('removecardsandtokens', '/audio/wizards2/removecardsandtokens.mp3', 10, 'notification');
        }
        this.updateCardState(event);;
    };

    handleCoinDetected = () => {
        this.coinbox.audioPlayer.ignoreAllBeforeNow('spellcast');
        this.coinbox.audioPlayer.ignoreAllBeforeNow('helper');
        //this.coinbox.audioPlayer.play('pling1', '/audio/wizards/pling1.mp3', 10, 'playing-coins'); // always on top of other coin playing sounds
        if (this.currentPlayer === 'player1') {
            this.player1ManaPlayed++;
        } else if (this.currentPlayer === 'player2') {
            this.player2ManaPlayed++;
        }
        this.eventLogger.logEvent('coin_detected');
        if (!this.gameIsActive) return; // its okay to randomly throw coins in the box when the game is not running
        if (!this.turnBasedActive || !this.currentCard || !this.readyForPayment) {
            if (this.cardQueue.length > 0) {
                console.log('Coin detected - not in turnbased mode or no card or not ready for payment - so we trash it');
                // get card info from next card
                const creature = this.cardQueue[0];
                let playerToPay = creature.player;
                if (this.gameMode === '2v2') {
                    playerToPay = this.getPlayerBasedOnCardTypeId(creature.cardTypeId);
                }
                const playerTray = this.getTrayFromPlayer(playerToPay);
                this.coinbox.setInputTray(playerTray);
            } else {
                if (this.gameMode === '1v1') {
                    const playerTray = this.getTrayFromPlayer('player3');
                    this.coinbox.setInputTray(playerTray);
                } else {
                    const playerTray = this.getTrayFromPlayer('player1');
                    this.coinbox.setInputTray(playerTray);
                }
            }
            this.coinbox.setTrashNextCoin();
            return;
        }
        // set what colors to trash becuase they are not in the cost of the card
        const allColors = ['blue', 'red', 'green', 'yellow', 'purple'];
        const colorsInCost = this.currentCard.manaCost;
        const colorsNotInCost = allColors.filter(color => !colorsInCost.includes(color));
        const colorsNotInCostNumbers = colorsNotInCost.map(color => this.coinbox.colorNames.indexOf(color));
        this.coinbox.trashNextCoinColors = colorsNotInCostNumbers;
    };

    handleCoinColorDetected = (event) => {
        const { action, colorname } = event;
        if (action !== 'coin' || !this.turnBasedActive || !this.currentCard || !this.readyForPayment) return;
        /*
        if(!this.currentCard || !this.readyForPayment){
            if (this.cardQueue.length > 0) {
                this.nextCardColorPayedFor.push(colorname);
            }
            console.log('Coin detected: ', colorname);
            this.eventLogger.logEvent('coin_detected_color', {
                action,
                colorname,
                status: 'prepaid'
            });
            return;
        } */

        console.log('Coin detected: ', colorname);
        this.eventLogger.logEvent('coin_detected_color', {
            action,
            colorname,
        });
        const costIndex = this.currentCard.manaCost.indexOf(colorname);
        if (costIndex > -1) {
            this.helperSoundPriority--;
            this.currentCard.manaCost.splice(costIndex, 1);
            this.eventLogger.logEvent('coin_paid', {
                action,
                colorname,
            });
            if (this.currentCard.manaCost.length === 0) {
                this.coinbox.audioPlayer.ignoreAllBeforeNow('helper');
                const sound = colorname + '_sound';
                this.coinbox.audioPlayer.play(sound, '/audio/wizards2/' + sound + '.mp3', 10, 'helper');
                console.log('Card fully paid!');
                this.updateCardState(this.currentCard);
                this.finalizeCardPayment();
            } else {
                this.coinbox.audioPlayer.ignoreAllBeforeNow('helper');
                console.log('Card not yet fully paid', Date.now());
                const sound = colorname + '_sound';
                this.coinbox.audioPlayer.play(sound, '/audio/wizards2/' + sound + '.mp3', 10, 'helper');
                this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'helper');
                this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'helper');
                this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'helper');
                this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'helper');
                this.coinbox.audioPlayer.queueOrPlay('now_pay', '/audio/wizards2/now_pay.mp3', 10, 'helper');
                const colorCount = this.currentCard.manaCost.reduce((acc, color) => {
                    acc[color] = (acc[color] || 0) + 1;
                    return acc;
                }, {});
                for (const [color, count] of Object.entries(colorCount)) {
                    this.coinbox.audioPlayer.queueOrPlay('number_' + count, '/audio/wizards/number_' + count + '.mp3', 10, 'helper');
                    this.coinbox.audioPlayer.queueOrPlay('resource_' + color, '/audio/wizards/color_' + color + '.mp3', 10, 'helper');
                    this.coinbox.audioPlayer.queueOrPlay('pause_250', '/audio/wizards/pause_250.mp3', 10, 'helper');
                }
                console.log('Card not yet fully paid - DONE', Date.now());
                this.updateCardState(this.currentCard);
            }
        } else {
            // wrong color

        }
    };

    handleCoinOutput = (event) => {
        const { outputTray, colorname } = event;
        this.eventLogger.logEvent('coin_output', {
            outputTray,
            colorname,
        });
        setTimeout(() => {
            this.updateRealTimeScores();
        }, 10);
    };

    handleConnection = (event) => {
        const { status } = event;
        this.eventLogger.logEvent('connection', {
            status,
        });
        console.log('connectionEvent', status);
    };

    handleGameEvent(event) {
        if (this.currentState !== 'realTime') return false;
        this.turnbasedTimeStart = Date.now();
        this.currentPlayer = 'box';
        this.pauseGame();
        if (event.eventId === 1) {
            this.transitionToState('changeCardsEvent');
        } else if (event.eventId === 2) {
            this.transitionToState('overtime');
        }
        return true;
    }

    // ------- CARD HANDLERS  --------------

    // Card Handlers
    handleCardPlayed(player, cardTypeId, cardId) {
        const cardType = this.getCardType(cardTypeId);
        const playerStr = `player${player}`;

        if (!this.gameIsActive){
            if(cardType === 'life'){
                if (cardId < 20 || cardId > 30) return;
                const lifeCard = {
                    player: playerStr,
                    cardType,
                    cardId,
                };    
                const lifename = 'life' + this.handleLifeCard(lifeCard);
                this.coinbox.audioPlayer.queueOrPlay(lifename, '/audio/wizards2/' + lifename + '.mp3', 10, 'spellcast');
            }
            return;
        }

        this.eventLogger.logEvent('card_played', {
            player,
            cardId,
            cardType,
        });

        if (cardType === 'unknown') return;


        if (cardType === 'spell' && this.manaCardIdsPlayed.includes(cardId)) {
            // not a spell played as a spell
            return;
        }

       
        const position = this.getCardPosition(cardTypeId);

        if (cardType === 'life') {
            if (cardId < 20 || cardId > 30) return;

            const lifeCard = {
                player: playerStr,
                cardType,
                cardId,
            };

            const lifename = 'life' + this.handleLifeCard(lifeCard);
            if (this.currentCreatureAttack) {
                this.resolveCreatureAttack();
            }
            this.coinbox.audioPlayer.queueOrPlay(lifename, '/audio/wizards2/' + lifename + '.mp3', 10, 'spellcast');

            if (!this.turnBasedActive) {
                this.updateRealTimeScores();
            }
            return;
        }

        if (cardType === 'creature') {
            const creatureIndex = this.creatures.findIndex(
                (creature) =>
                    creature.player === playerStr &&
                    creature.cardId === cardId
            );
            if (creatureIndex !== -1) {
                console.log('Creature already in play');
                this.creatures[creatureIndex].position = position;
                return;
            }
            const graveyardIndex = this.graveyard.findIndex(
                (creature) =>
                    creature.player === playerStr &&
                    creature.cardId === cardId
            );
            if (graveyardIndex !== -1) {
                const creature = this.graveyard[graveyardIndex];
                if ((creature.putInGraveyardTime + 30000) > Date.now()) {
                    creature.position = position;
                    this.graveyard.splice(graveyardIndex, 1);
                    this.creatures.push(creature);
                    this.creatureAttackManager.addCreature(creature);
                    console.log(`Creature restored for ${playerStr} at position ${position}`);
                    this.eventLogger.logEvent('creature_back_from_graveyard', {
                        player,
                        cardId,
                        cardType,
                    });
                    const channel = this.backgroundAudio[creature.player];
                    this.coinbox.audioPlayer.queueOrPlay(creature.shortName, '/audio/wizards2/' + creature.shortName + '.mp3', 10, channel);
                    this.coinbox.audioPlayer.queueOrPlay('backfromgraveyard', '/audio/wizards2/backfromgraveyard.mp3', 10, channel);
                    const effect = creature.effect;
                    if (effect !== '') {
                        const isManaEffect = effect.includes('mana');
                        if (isManaEffect) {
                            let playerToGetMana = creature.player;
                            if (this.gameMode === '2v2') {
                                playerToGetMana = this.getPlayerBasedOnCardTypeId(creature.cardTypeId);
                            }

                            const colorName = effect.split('_')[1];
                            this.coinGeneration[playerToGetMana].colors.push(colorName);
                            this.coinbox.updateCoinColorInOutputQueue(playerToGetMana, colorName);

                            console.log('Mana added:', colorName, this.coinGeneration[player].colors);
                            this.eventLogger.logEvent('creature_played', {
                                creature,
                                'effect': 'mana',
                            });
                            this.coinbox.audioPlayer.queueOrPlay('mana_added', '/audio/wizards2/mana_added.mp3', 10, 'spellcast');
                        }
                    }
                    if (!this.turnBasedActive) {
                        this.updateRealTimeScores();
                    }
                    return;
                } else {
                    this.graveyard.splice(graveyardIndex, 1);
                }
            }
        }

        const card = {
            player: playerStr,
            cardType,
            cardId,
            speed: this.getCardSpeed(cardId),
            manaCost: [...this.getCardCost(cardId)],
            name: this.getCardName(cardId),
            shortName: this.getCardShortName(cardId),
            effect: this.getCardEffect(cardId),
            position,
            cardTypeId,
            globalPlayerId: this.getPlayerBasedOnCardTypeId(cardTypeId),
            timestamp: Date.now(),
        };

        if (this.turnBasedActive) {
            console.log(`Player ${playerStr} played card during turn-based mode - QUEUED `, this.getCardName(cardId));
            // here i would do a speciel trick to help the case where players pay for there creature too fast while an attack is announched at the excat same time.
            if (this.cardQueue.length === 0 && this.currentCreatureAttack !== null) {
                // ongoing attack and you just played a card - its not your turn but lets set the tray to you 
                let tray = this.coinGeneration[playerStr].tray;
                if (this.gameMode === '2v2') {
                    const playerToPay = this.getPlayerBasedOnCardTypeId(cardTypeId);
                    tray = this.getTrayFromPlayer(playerToPay);
                }
                this.coinbox.setInputTray(tray);
            }
            const channel = this.backgroundAudio[card.player];
            if (cardType === 'creature') {
                this.coinbox.audioPlayer.queueOrPlay('recruitment_queued_for', '/audio/wizards2/recruitment_queued_for.mp3', 10, channel);
                this.coinbox.audioPlayer.queueOrPlay(card.shortName, '/audio/wizards2/' + card.shortName + '.mp3', 10, channel);
            } else {
                const sound = this.getColorNameFromCard(card.cardId) + '_on_queue';
                this.coinbox.audioPlayer.queueOrPlay(sound, '/audio/wizards2/' + sound + '.mp3', 10, channel);
            }
            this.cardQueue.push(card);
            this.eventLogger.logEvent('card_put_in_queue', {
                player,
                cardId,
                cardType,
            });
        } else {
            this.eventLogger.logEvent('card_active', {
                player,
                cardId,
                cardType,
            });
            console.log(`Player ${playerStr} played card during REALTIME `, this.getCardName(cardId));
            this.currentCard = card;
            this.transitionToState('turnBased');
        }
    }

    handleCardRemoved(playerId, cardTypeId, cardId) {
        if (!this.gameIsActive) return;

        const cardType = this.getCardType(cardTypeId);
        const player = `player${playerId}`;
        const globalPlayerId = this.getPlayerBasedOnCardTypeId(cardTypeId);

        this.eventLogger.logEvent('card_removed', {
            playerId,
            cardId,
            cardType,
        });

        if (cardType === 'life') {
            return;
        }


        if (cardType === 'spell' && this.manaCardIdsPlayed.includes(cardId)) {
            return;
        }

        const initialQueueLength = this.cardQueue.length;
        this.cardQueue = this.cardQueue.filter(
            (queuedCard) => !((queuedCard.player === player || (this.gameMode === '2v2' && queuedCard.globalPlayerId === globalPlayerId)) && queuedCard.cardId === cardId && ((queuedCard.cardType === 'creature' && queuedCard.position === this.getCardPosition(cardTypeId)) || queuedCard.cardType !== 'creature'))
        );
        if (this.cardQueue.length < initialQueueLength) {
            const shortName = this.getCardShortName(cardId);
            console.log(`Player ${player} removed card in queue before it was paid for`, shortName);
            this.eventLogger.logEvent('card_removed_from_queue', {
                playerId,
                cardId,
                cardType,
            });
            const channel = this.backgroundAudio[player];
            if (cardType === 'creature') {
                this.coinbox.audioPlayer.queueOrPlay('recruitment_canceled_for', '/audio/wizards2/recruitment_canceled_for.mp3', 10, channel);
                this.coinbox.audioPlayer.queueOrPlay(shortName, '/audio/wizards2/' + shortName + '.mp3', 10, channel);
            } else {
                const sound = this.getColorNameFromCard(cardId) + '_off_queue';
                this.coinbox.audioPlayer.queueOrPlay(sound, '/audio/wizards2/' + sound + '.mp3', 10, channel);
            }
            return;
        }

        if (this.currentCard && this.currentCard.player === player && this.currentCard.cardId === cardId) {

            if (this.paymentComplete) {
                // this is not reelvant any more
                /*
                if (cardType === 'spell') {
                    this.eventLogger.logEvent('artefact_put_in_play', {
                        playerId,
                        cardId,
                        cardType,
                    });
                    this.coinbox.audioPlayer.ignoreAllBeforeNow('spellcast');
                    this.transitionToState('resumeCountDown');
                    return;
                }*/
            } else {
                console.log(`Player ${player} removed the active card before all payment was done`, this.getCardName(cardId));
                this.coinbox.audioPlayer.ignoreAllBeforeNow('spellcast');
                this.coinbox.audioPlayer.ignoreAllBeforeNow('helper');
                this.coinbox.useInputQueue();
                this.eventLogger.logEvent('card_removed_before_payed', {
                    playerId,
                    cardId,
                    cardType,
                });
                if (cardType === 'creature') {
                    this.coinbox.audioPlayer.queueOrPlay('recruitment_failed_for', '/audio/wizards2/recruitment_failed_for.mp3', 10, 'spellcast');
                    this.coinbox.audioPlayer.queueOrPlay(this.currentCard.shortName, '/audio/wizards2/' + this.currentCard.shortName + '.mp3', 10, 'spellcast');
                } else if (this.currentCard.payed) {
                    this.coinbox.audioPlayer.play('pause_250', '/audio/wizards/pause_250.mp3', 10, 'spellcast');
                } else if (!this.currentCard.payed) {
                    const sound = 'removed_' + this.getColorNameFromCard(cardId);
                    this.coinbox.audioPlayer.queueOrPlay(sound, '/audio/wizards2/' + sound + '.mp3', 10, 'spellcast');
                }
                this.currentCard = null;
                this.transitionToState('resumeCountDown');
                return;
            }
        }

        if (cardType === 'spell') {
            const shortName = this.getCardShortName(cardId);
            console.log(`Player ${player} removed a spell`, shortName);
            return;
        }

        if (this.currentCreatureAttack && cardType === 'creature') {
            console.log(`Player ${player} removed a creature during battle triggering attack change`, this.getCardName(cardId));
            this.eventLogger.logEvent('card_removed_during_attack', {
                playerId,
                cardId,
                cardType,
            });
            this.resolveCreatureAttack();
        }

        if (cardType === 'creature') {
            const position = this.getCardPosition(cardTypeId);

            for (let i = 0; i < this.creatures.length; i++) {
                const creature = this.creatures[i];
                if (
                    creature.player === player &&
                    creature.cardId === cardId &&
                    creature.position === position
                ) {
                    let remainingTime;
                    if (this.creatureAttackManager.isPaused) {
                        remainingTime = creature.remainingTime;
                    } else {
                        remainingTime = creature.nextAttackTime - Date.now();
                    }
                    this.creatureAttackManager.removeCreature(creature.creatureId);
                    creature.remainingTime = remainingTime;
                    creature.putInGraveyardTime = Date.now();
                    this.graveyard.push(creature);
                    this.creatureLeaveBoard(creature);
                    this.creatures.splice(i, 1);
                    console.log(`Creature moved to graveyard for ${player} at position ${position}`);

                    this.eventLogger.logEvent('creature_moved_to_graveyard', {
                        playerId,
                        cardId,
                        cardType,
                    });
                    if (!this.turnBasedActive) {
                        this.updateRealTimeScores();
                    }

                    return;
                }
            }
        }
    }

    // ------- PAYMENT  --------------

    async handlePayCost() {
        this.updateCardState(this.currentCard);

        this.readyForPayment = true;
        this.paymentComplete = false;
        //const team = 'player_' + (this.currentCard.player === 'player1' ? 'red' : 'blue');
        this.coinbox.audioPlayer.queueOrPlay('card_played', '/audio/wizards2/card_played.mp3', 10, 'spellcast');
        //this.coinbox.audioPlayer.queueOrPlay(team, '/audio/wizards2/' + team + '.mp3', 10, 'spellcast');

        let playerToPay = this.currentCard.player;
        if (this.gameMode === '2v2') {
            playerToPay = this.getPlayerBasedOnCardTypeId(this.currentCard.cardTypeId);
        }
        const playerTray = this.getTrayFromPlayer(playerToPay);
        console.log('Player to pay', playerToPay, playerTray);
        if (this.currentCard.cardType === 'spell') {
            const cardId = this.currentCard.cardId;
            const sound = 'pay_' + this.getColorNameFromCard(cardId);
            this.coinbox.audioPlayer.queueOrPlay(sound, '/audio/wizards2/' + sound + '.mp3', 10, 'spellcast');
        } else if (this.currentCard.cardType === 'creature') {
            this.coinbox.audioPlayer.queueOrPlay('pay_now_to_recruit', '/audio/wizards2/pay_now_to_recruit.mp3', 10, 'spellcast');
            this.coinbox.audioPlayer.queueOrPlay(this.currentCard.shortName, '/audio/wizards2/' + this.currentCard.shortName + '.mp3', 10, 'spellcast');
        }


        // lets check if the player already payed something (this.nextCardColorPayedFor) and 
        /*
        const prepayedCoins = [...this.nextCardColorPayedFor];
        this.nextCardColorPayedFor = [];
        const prepayedCoinCount = prepayedCoins.reduce((acc, color) => {
            acc[color] = (acc[color] || 0) + 1;
            return acc;
        }, {});
        */

        const availableCoins = this.coinbox.getCoinsOutColorNames(playerTray);
        const availableCoinCount = availableCoins.reduce((acc, color) => {
            acc[color] = (acc[color] || 0) + 1;
            return acc;
        }, {});
        const colorCount = this.currentCard.manaCost.reduce((acc, color) => {
            acc[color] = (acc[color] || 0) + 1;
            return acc;
        }, {});
        let canPaymentBeMade = true;
        console.log('Checking if payment can be made', colorCount, availableCoins, availableCoinCount);
        for (const [color, count] of Object.entries(colorCount)) {
            if (!availableCoinCount[color] || availableCoinCount[color] < count) {
                console.log('Not enough coins to pay for the card');
                canPaymentBeMade = false;
                break;
            }
        }

        this.eventLogger.logEvent('card_payment', {
            'card': this.currentCard,
            'cardCost': colorCount,
            'coinsAvailable': availableCoinCount,
            canPaymentBeMade,
            'noCoinsMode': this.noCoinsMode,
        });


        if (!canPaymentBeMade) {
            // you need to remove the card from the board
            // we need to play audio and wait for card to be removed
            this.coinbox.audioPlayer.queueOrPlay('cannot_pay', '/audio/wizards2/cannot_pay.mp3', 10, 'spellcast');
            await this.coinbox.audioPlayer.waitForTrackNameToEnd('spellcast');
            return;
        }

        if (this.noCoinsMode) {
            await this.coinbox.audioPlayer.waitForTrackNameToEnd('spellcast');
            // i also need to insert the coins into the coinbox without sending the event.
            // we have to loop the colornames from this.currentCard.manaCost - find the correct index and insert the coin
            let firstCoin = true;
            const colorNamesToPay = [...this.currentCard.manaCost];
            for (const colorName of colorNamesToPay) {
                if (!firstCoin) {
                    await this.coinbox.wait(1000);
                }
                const colorIndex = this.colorNames.indexOf(colorName);
                this.coinbox.coinDetected();
                this.coinbox.addToInputQueue(colorIndex);
                firstCoin = false;
            }
            return;
        }

        this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay('you_need_to_pay', '/audio/wizards2/you_need_to_pay.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay('pause_250', '/audio/wizards/pause_250.mp3', 10, 'spellcast');
        for (const [color, count] of Object.entries(colorCount)) {
            this.coinbox.audioPlayer.queueOrPlay('number_' + count, '/audio/wizards/number_' + count + '.mp3', 10, 'spellcast');
            this.coinbox.audioPlayer.queueOrPlay('resource_' + color, '/audio/wizards/color_' + color + '.mp3', 10, 'spellcast');
            this.coinbox.audioPlayer.queueOrPlay('pause_250', '/audio/wizards/pause_250.mp3', 10, 'spellcast');
        }
    }

    async finalizeCardPayment() {
        console.log(`Card payment finalized for ${this.currentCard.player}`);
        this.coinbox.useInputQueue();
        this.readyForPayment = false;
        this.paymentComplete = true;

        await this.coinbox.audioPlayer.waitForTrackNameToEnd('helper');
        this.eventLogger.logEvent('card_payment_finalized');
        if (this.currentCard.cardType === 'creature') {
            this.playCreatureCard();
        } else if (this.currentCard.cardType === 'spell') {
            this.playSpellCard();
        }
    }


    // ------- PUT CARD ONTO BOARD  --------------

    creatureComeIntoPlay(creature) {
        const effect = creature.effect;
        const player = creature.player;
        if (effect !== '') {
            const isManaEffect = effect.includes('mana');
            if (isManaEffect) {
                let playerToGetMana = player;
                if (this.gameMode === '2v2') {
                    playerToGetMana = this.getPlayerBasedOnCardTypeId(creature.cardTypeId);
                }

                const colorName = effect.split('_')[1];
                this.coinGeneration[playerToGetMana].colors.push(colorName);
                this.coinbox.updateCoinColorInOutputQueue(playerToGetMana, colorName);

                console.log('Mana added:', colorName, this.coinGeneration[player].colors);
                this.eventLogger.logEvent('creature_played', {
                    creature,
                    'effect': 'mana',
                });
                this.coinbox.audioPlayer.queueOrPlay('mana_added', '/audio/wizards2/mana_added.mp3', 10, 'spellcast');
            }
            const isChargeEffect = effect.includes('charge');
            if (isChargeEffect) {
                const opponent = player === 'player1' ? 'player2' : 'player1';
                const opponentCreatures = this.creatures.filter(creatureInPlay => creatureInPlay.player === opponent);
                const hasTargets = opponentCreatures.length > 0;
                if (hasTargets) {
                    this.cardQueue.unshift({ ...creature, isAttack: true, manaCost: [], isChargeAttack: true });
                }
                this.eventLogger.logEvent('creature_played', {
                    creature,
                    'effect': 'charge',
                    'targets': hasTargets,
                });
            }

        } else {
            this.eventLogger.logEvent('creature_played', {
                creature,
            });
        }

    }

    creatureLeaveBoard(creature) {
        const effect = creature.effect;
        const player = creature.player;
        if (effect !== '') {
            const isManaEffect = effect.includes('mana');
            if (isManaEffect) {
                const colorName = effect.split('_')[1];
                // here i need to remove the color from the colors array in the coinGeneration object - i want to finde the last index first
                const index = this.coinGeneration[player].colors.lastIndexOf(colorName);
                if (index > -1) {
                    this.coinGeneration[player].colors.splice(index, 1);
                    const channel = this.backgroundAudio[player];
                    console.log('Mana removed:', colorName, this.coinGeneration[player].colors);
                    this.coinbox.audioPlayer.queueOrPlay('mana_removed', '/audio/wizards2/mana_removed.mp3', 10, channel);
                }
            }
        }
    }

    async playCreatureCard() {

        this.coinbox.audioPlayer.play(this.currentCard.shortName, '/audio/wizards2/' + this.currentCard.shortName + '.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay('joins_the_brawl', '/audio/wizards2/joins_the_brawl.mp3', 10, 'spellcast');
        await this.coinbox.audioPlayer.waitForTrackNameToEnd('spellcast');

        console.log(`Playing creature card for ${this.currentCard.player}`);
        if (this.currentPlayer === 'player1') {
            this.player1CreaturesPlayed++;
        } else if (this.currentPlayer === 'player2') {
            this.player2CreaturesPlayed++;
        }
        this.creatureId++;
        const creature = { ...this.currentCard, creatureId: this.creatureId };
        this.creatures.push(creature);
        this.creatureAttackManager.addCreature(creature);


        this.creatureComeIntoPlay(this.currentCard);
        this.transitionToState('resumeCountDown');
    }

    playSpellCard() {
        // mana
        const cardId = this.currentCard.cardId;
        this.currentCard.payed = true;
        const colorName = this.getColorNameFromCard(cardId);;

        this.manaCardIdsPlayed.push(cardId);

        this.eventLogger.logEvent('artefact_played', {
            cardId,
            'mana': colorName,
        });

        const sound = 'active_' + colorName;
        this.coinbox.audioPlayer.queueOrPlay(sound, '/audio/wizards2/' + sound + '.mp3', 10, 'spellcast');

        let playerToGetMana = this.currentPlayer;
        if (this.gameMode === '2v2') {
            playerToGetMana = this.getPlayerBasedOnCardTypeId(this.currentCard.cardTypeId);
        }
        this.coinGeneration[playerToGetMana].colors.push(colorName);
        this.coinbox.updateCoinColorInOutputQueue(playerToGetMana, colorName);

        console.log('Mana added:', colorName, this.coinGeneration[this.currentPlayer].colors);
        this.coinbox.audioPlayer.queueOrPlay('mana_added', '/audio/wizards2/mana_added.mp3', 10, 'spellcast');
        //this.coinbox.audioPlayer.queueOrPlay('removemanacard', '/audio/wizards2/removemanacard.mp3', 10, 'spellcast');

        this.updateCardState(this.currentCard);

        console.log("spell played: ", this.currentCard.name);
        this.transitionToState('resumeCountDown');

    }

    handleLifeCard(card) {
        const playerState = this.playerStates[card.player];
        playerState.life = card.cardId - 20;
        this.eventLogger.logEvent('alife_updated', {
            'player': card.player,
            life: playerState.life,
        });
        console.log(`Updated ${card.player} life to ${playerState.life}`);
        return playerState.life;
    }

    processQueuedCards() {
        if (this.cardQueue.length > 0) {
            console.log('Processing queued cards');
            const nextItem = this.cardQueue.shift();
            console.log(nextItem);
            this.eventLogger.logEvent('process_queued_cards', {
                'card': nextItem,
            });
            if (nextItem.isChargeAttack) {
                const opponent = nextItem.player === 'player1' ? 'player2' : 'player1';
                const opponentCreatures = this.creatures.filter(creatureInPlay => creatureInPlay.player === opponent);
                if (opponentCreatures.length > 0) {
                    this.currentCreatureAttack = nextItem;
                    this.currentCard = null;
                }
            } else if (nextItem.isAttack) {
                this.currentCreatureAttack = nextItem;
                this.currentCard = null;
            } else {
                this.currentCard = nextItem;
                this.currentCreatureAttack = null;
            }
            this.transitionToState('turnBased');
            return true;
        }
        return false;
    }


    // Creature Attack Handling
    handleCreatureReadyToAttack(creature) {
        console.log(`${creature.player}'s creature is ready to attack`);
        if (this.turnBasedActive) {
            console.log('------------------------------- Creature attack queued -------------------------------');
            this.cardQueue.push({ ...creature, isAttack: true, manaCost: [] });
        } else {
            this.currentCreatureAttack = { ...creature, isAttack: true, manaCost: [] };
            this.currentCard = null;
            this.transitionToState('turnBased');
        }
    }

    handleCreatureAttack(creature) {
        console.log(creature);
        this.eventLogger.logEvent('attack_started', {
            creature,
        });
        this.updateCardState(creature);
        console.log(`${creature.player}'s creature is attacking`);
        this.coinbox.audioPlayer.queueOrPlay('attack', '/audio/wizards2/attack.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay('attack_starts', '/audio/wizards2/attack_starts.mp3', 10, 'spellcast');
        //const team = 'player_' + (creature.player === 'player1' ? 'red' : 'blue');
        //this.coinbox.audioPlayer.queueOrPlay(team, '/audio/wizards2/' + team + '.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay(creature.shortName, '/audio/wizards2/' + creature.shortName + '.mp3', 10, 'spellcast');
        this.coinbox.audioPlayer.queueOrPlay('creature_attacks', '/audio/wizards2/creature_attacks.mp3', 10, 'spellcast');

        if (creature.isChargeAttack) {
            console.log('Charge attack');
            // we need to check the graveyard for the creature from the oppponent that was just put in the graveyard in the last say 5 seconds
            const opponent = creature.player === 'player1' ? 'player2' : 'player1';
            const opponentCreatures = this.graveyard.filter(creatureInGraveyard => creatureInGraveyard.player === opponent && (creatureInGraveyard.putInGraveyardTime > creature.timestamp));
            if (opponentCreatures.length > 0) {
                console.log('Charge attack - creature found in graveyard', opponentCreatures);
                this.resolveCreatureAttack();
                return;
            }
        }
    }

    resolveCreatureAttack() {
        if (this.resolveAttackTimer) {
            clearTimeout(this.resolveAttackTimer);
            this.resolveAttackTimer = null;
        }

        this.coinbox.audioPlayer.queueOrPlay(
            'ai_mocamedes_hurt',
            '/audio/wizards/ai_mocamedes_hurt.mp3',
            10,
            'spellcast'
        );   // not the best channel

        this.currentCreatureAttack.boardStateChanged = true;
        this.updateCardState(this.currentCreatureAttack);

        this.resolveAttackTimer = setTimeout(() => {
            this.currentCreatureAttack = null;
            this.resolveAttackTimer = null;
            this.eventLogger.logEvent('attack_ended');
            this.transitionToState('resumeCountDown');
        }, 4000);
    }

    resolveCurrentCard() {
        if (this.currentCard) {
            this.currentPlayer = this.currentCard.player;
            this.updateColorMode();
            if (this.currentCard.cardType === 'creature' || this.currentCard.cardType === 'spell') {
                this.handlePayCost();
            } else {
                this.transitionToState('resumeCountDown');
            }
        } else if (this.currentCreatureAttack) {
            this.currentPlayer = this.currentCreatureAttack.player;
            this.updateColorMode();
            this.handleCreatureAttack(this.currentCreatureAttack);
        } else {
            this.transitionToState('resumeCountDown');
        }
    }



    // Utility Methods
    getCardType(cardTypeId) {
        const cardTypeMapping = {
            2: 'life',
            6: 'spell',
            7: 'spell',
            8: 'spell',
            9: 'spell',
            11: 'creature',
            12: 'creature',
            13: 'creature',
            14: 'creature',
            15: 'creature',
            21: 'creature',
            22: 'creature',
            23: 'creature',
            24: 'creature',
            25: 'creature',
        };
        return cardTypeMapping[cardTypeId] || 'unknown';
    }

    getCardPosition(cardTypeId) {
        const positionMapping = {
            11: 1,
            12: 2,
            13: 3,
            14: 4,
            15: 5,
            21: 1,
            22: 2,
            23: 3,
            24: 4,
            25: 5,
        };
        return positionMapping[cardTypeId] || 0;
    }

    getPlayerBasedOnCardTypeId(cardTypeId) {
        const positionMapping = {
            6: 'player3',
            7: 'player2',
            8: 'player4',
            9: 'player1',
            11: 'player3',
            12: 'player3',
            13: 'player2',
            14: 'player2',
            15: 'player2',
            21: 'player1',
            22: 'player1',
            23: 'player1',
            24: 'player4',
            25: 'player4',
        };
        return positionMapping[cardTypeId] || 'unknown';
    }

    getTrayFromPlayer(player) {
        let tray = 'tray';
        if (player === 1 || player === '1' || player === 'player1') {
            tray += '1';
        } else if (player === 2 || player === '2' || player === 'player2') {
            tray += '2';
        } else if (player === 3 || player === '3' || player === 'player3') {
            tray += '3';
        } else if (player === 4 || player === '4' || player === 'player4') {
            tray += '4';
        }
        return tray;
    }

    getColorNameFromCard(cardId) {

        const colorMapping = {
            111: 'red',
            112: 'red',
            113: 'red',
            114: 'red',
            115: 'yellow',
            116: 'yellow',
            117: 'yellow',
            118: 'yellow',
            119: 'blue',
            120: 'blue',
            121: 'blue',
            122: 'blue',
            123: 'green',
            124: 'green',
            125: 'green',
            126: 'green',
            127: 'purple',
            128: 'purple',
            129: 'purple',
            130: 'purple',
            131: 'red',
            132: 'red',
            133: 'yellow',
            134: 'yellow',
            135: 'blue',
            136: 'blue',
            137: 'green',
            138: 'green',
            139: 'purple',
            140: 'purple',
        };
        return colorMapping[cardId] || 'unknown';
    }

    getCardCost(cardId) {
        const card = this.cards[`card${cardId}`];
        return card ? card.cost : [];
    }

    getCardName(cardId) {
        const card = this.cards[`card${cardId}`];
        return card ? card.name : '';
    }

    getCardEffect(cardId) {
        const card = this.cards[`card${cardId}`];
        return card ? card.effect : '';
    }

    getCardShortName(cardId) {
        const card = this.cards[`card${cardId}`];
        return card ? card.name.toLowerCase().replace(' ', '_') : '';
    }

    getCardSpeed(cardId) {
        const card = this.cards[`card${cardId}`];
        return card ? card.speed : 0;
    }

    async updateColorMode() {
        if (this.currentPlayer === 'box') {
            this.coinbox.setColorMode(3);
        } else {
            const mode = (this.currentPlayer === 'player1' ? 2 : 0);
            this.coinbox.setColorMode(mode);
            const tray = this.coinGeneration[this.currentPlayer].tray;
            this.coinbox.setInputTray(tray);
            if (this.currentCard) {
                let playerToPay = this.currentCard.player;
                if (this.gameMode === '2v2') {
                    playerToPay = this.getPlayerBasedOnCardTypeId(this.currentCard.cardTypeId);
                }
                const playerTray = this.getTrayFromPlayer(playerToPay);
                this.coinbox.setInputTray(playerTray);
            }
        }
        console.log('updateColorMode - PAUSEOUTPUT:', this.currentPlayer);
    }

    updateCardState(card) {
        if (this.onCardStateChange) {
            this.onCardStateChange({ ...card });
        }
    }





    // ------- handle coin generator events





    coinGeneratorSetup = () => {
        this.coinbox.on('input', this.coinGeneratorRestart);
        for (const [id, data] of Object.entries(this.coinGeneration)) {
            data.colors = this.coinbox.random.shuffle([...data.colors]);
            this.coinbox.on(id, this.coinGenerator);
        }
    }

    coinGeneratorStart = () => {
        for (const [id] of Object.entries(this.coinGeneration)) {
            this.coinGeneratorAddCoin(id);
        }
    }

    coinGeneratorAtMaxCapacity = (color, maxCoins, tray) => {
        const coinsInPlay = this.coinbox.coinsInPlay(color, tray);
        return coinsInPlay >= maxCoins;
    }

    coinGeneratorIsRunning = (id) => {
        return this.coinbox.getAllFromOutputQueue().some(coin => coin.callbackName === id)
    }

    coinGeneratorInstantOutput = (id) => {
        const coin = this.coinbox.getAllFromOutputQueue().find(coin => coin.callbackName === id);
        //console.log('coinGeneratorInstantOutput:', coin);
        const now = Date.now();
        if (!coin || coin.timestamp <= now) return false;
        coin.timestamp = now;
        return true;
    }

    coinGeneratorAddCoin = (id) => {
        console.log('coinGeneratorAddCoin:', id);
        if (this.coinGeneratorIsRunning(id)) return false;
        const data = this.coinGeneration[id];
        const numberOfCoinsInPool = data.colors.length;
        console.log('coinGeneratorAddCoin - length:', numberOfCoinsInPool);
        let maxTries = numberOfCoinsInPool;
        data.index = Math.floor(Math.random() * numberOfCoinsInPool);  // set to a random start position - its not completly random but it will do
        while (maxTries > 0) {
            maxTries -= 1;
            const index = data.index;
            data.index = (index + 1) % numberOfCoinsInPool;
            const colorName = data.colors[index];
            const color = this.colorNames.indexOf(colorName);
            const capacity = data.colors.filter(c => c === colorName).length;
            if (this.coinGeneratorAtMaxCapacity(color, capacity, data.tray)) continue;
            let timeout = Date.now() + (data.firstNumberCoins > 0 ? data.firstdelay : data.delay);
            data.firstNumberCoins -= 1;
            if (timeout < Date.now()) timeout = Date.now() + 500;
            this.coinbox.addToOutputQueue(color, timeout, id, data.tray);
            console.log('coinGeneratorAddCoin - addToOutputQueue:', colorName, timeout, id, data.tray);
            return true;
        }
        return false;
    }

    coinGenerator = (event) => {
        this.coinGeneratorAddCoin(event.callbackName);
    }

    coinGeneratorRestart = (event) => {
        if (event.action === 'cancel') {
            console.log('cancel coin');
        } else if (event.action !== 'used') return;
        this.coinGeneratorStart();
    }

}

export default Wizards3Client;